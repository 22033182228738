import BaseComponent from './BaseComponent'
import autosize      from 'autosize'
import EasyMDE       from 'easymde'

class InputTextArea extends BaseComponent {
  constructor(componentName, autoInit){
    super(componentName)
    this.defaultToolbar = ['heading-smaller', 'heading-bigger', 'link']
    this.options = {
      spellChecker: false,
      status: false,
      shortcuts: {
        'cleanBlock': null
      },
      // showIcons: ['heading', 'heading-smaller','heading-bigger', 'heading-1', 'heading-2', 'heading-3'],
      hideIcons: [
        'bold',
        'italic',
        'quote',
        'unordered-list',
        'ordered-list',
        'guide',
        'image',
        'preview',
        'side-by-side',
        'fullscreen',
      ],
      toolbar: this.defaultToolbar
    }
  }


  init(component) {
    const element  = component.element
    const markdown = element.dataset.markdown === 'true'

    if(markdown) {
      // Add alignment options if needed
      const customToolbar = {}
      if(element.dataset.alignments == 'true') customToolbar.alignments = true
      if(element.dataset.headings == 'true') customToolbar.headings = true
      this.addCustomToolbarButtons(element, customToolbar)

      // Init component library
      component.wysiwyg = new EasyMDE({ element, ...this.options })
      component.wysiwyg.value()
      component.wysiwyg.codemirror.options.readOnly = element.readOnly

      // Reset default toolbar
      this.options.toolbar = this.defaultToolbar
    } else {
      component.autosize = autosize(element)
    }

    this.addCustomEvents(component, markdown)
    this.checkForFocus(component, markdown)
  }


  addCustomToolbarButtons(element, toolbar) {
    const parent = element.parentElement

    this.options.toolbar = [
      // ...(toolbar.headings ? ['heading-smaller', 'heading-bigger'] : []),
      ...(toolbar.headings ? [
        {
          name: 'heading',
          className: 'fa fa-heading',
          title: 'Headings List',
          children: [
            {
              name: 'heading-2',
              action: EasyMDE.toggleHeading2,
              className: 'fa fa-header header-2',
              title: 'Text 90pt',
            },
            {
              name: 'heading-3',
              action: EasyMDE.toggleHeading3,
              className: 'fa fa-header header-3',
              title: 'Text 50pt',
						},
						{
              name: 'clean-block',
              action: EasyMDE.cleanBlock,
              className: 'fa fa-eraser',
              title: 'Text 24pt',
            },
            {
              name: 'heading-4',
              action: (editor) => {
                editor.toggleHeading3()
                editor.toggleHeadingSmaller()
              },
              className: 'fa fa-header header-3',
              title: 'Text 18pt',
            },
            {
              name: 'heading-5',
              action: (editor) => {
                editor.toggleHeading3()
                editor.toggleHeadingSmaller()
                editor.toggleHeadingSmaller()
              },
              className: 'fa fa-header header-3',
              title: 'Text 12pt',
            },
            {
              name: 'heading-5',
              action: (editor) => {
                editor.toggleHeading3()
                editor.toggleHeadingSmaller()
                editor.toggleHeadingSmaller()
                editor.toggleHeadingSmaller()
              },
              className: 'fa fa-header header-3',
              title: 'Text 12pt',
            }
          ]
        },
      ] : []),
      ...(toolbar.alignments ? [
        {
          name: 'alignleft',
          action: (editor) => {
            const inputWrapper = editor.element.parentElement
            inputWrapper.classList.remove('align-center')
            inputWrapper.classList.remove('align-right')
            inputWrapper.classList.add('align-left')

            editor.toolbarElements.aligncenter.classList.remove('visible')
            editor.toolbarElements.alignright.classList.remove('visible')
            editor.toolbarElements.alignleft.classList.add('visible')

            const alignInput = inputWrapper.querySelector('input[type="hidden"]')
            if(alignInput) alignInput.value = 'left'
          },
          className: `fa fa-align-left${parent.classList.contains('left') ? ' visible' : ''}`,
          title: 'Align left',
        },
        {
          name: 'aligncenter',
          action: (editor) => {
            const inputWrapper = editor.element.parentElement
            inputWrapper.classList.remove('align-left')
            inputWrapper.classList.remove('align-right')
            inputWrapper.classList.add('align-center')

            editor.toolbarElements.alignleft.classList.remove('visible')
            editor.toolbarElements.alignright.classList.remove('visible')
            editor.toolbarElements.aligncenter.classList.add('visible')

            const alignInput = inputWrapper.querySelector('input[type="hidden"]')
            if(alignInput) alignInput.value = 'center'
          },
          className: `fa fa-align-center${parent.classList.contains('center') ? ' visible' : ''}`,
          title: 'Align center',
        },
        {
          name: 'alignright',
          action: (editor) => {
            const inputWrapper = editor.element.parentElement
            inputWrapper.classList.remove('align-left')
            inputWrapper.classList.remove('align-center')
            inputWrapper.classList.add('align-right')

            editor.toolbarElements.alignleft.classList.remove('visible')
            editor.toolbarElements.aligncenter.classList.remove('visible')
            editor.toolbarElements.alignright.classList.add('visible')

            const alignInput = inputWrapper.querySelector('input[type="hidden"]')
            if(alignInput) alignInput.value = 'right'
          },
          className: `fa fa-align-right${parent.classList.contains('right') ? ' visible' : ''}`,
          title: 'Align right',
        }
      ] : []),
      {
        name: 'email',
        action: (editor) => {
          var cm = editor.codemirror;
          var stat = editor.getState(cm); // or true
          var options = editor.options;
          var url = 'mailto:';

          this._replaceSelection(cm, stat.link, options.insertTexts.link, url);
        },
        className: 'fa fa-envelope',
        title: 'Email'
      },
      'link'
    ]
  }


  addCustomEvents(component, markdown = true) {
    const element = component.element

    if(markdown) {
      const cm = component.wysiwyg.codemirror;

      cm.on('focus', () => { element.classList.add('is-focused') })
      cm.on('blur', () => { element.classList.remove('is-focused') })

      if(element.dataset.headings == 'true') {
        const h2 = component.wysiwyg.toolbarElements['heading-2'];
        const p  = component.wysiwyg.toolbarElements['clean-block'];
        const textareaWrapper = component.element.parentElement
        const currentTextType = textareaWrapper.querySelector('span[class="current-text-type"]')
        const textType = {
          h1: 'Text 90pt',
          h2: 'Text 90pt',
          h3: 'Text 50pt',
          p: 'Text 24pt',
          h4: 'Text 18pt',
          h5: 'Text 12pt',
        }


        // https://codemirror.net/doc/manual.html#api_marker
        cm.on('cursorActivity', (editor) => {
          const index = cm.getCursor().line
          const line = cm.display.renderedView[index]
          if(line) {
            const lineText = cm.display.renderedView[index].line.text
            if(lineText.startsWith('###### ')) currentTextType.textContent = textType.h5
            else if(lineText.startsWith('##### ')) currentTextType.textContent = textType.h5
            else if(lineText.startsWith('#### ')) currentTextType.textContent = textType.h4
            else if(lineText.startsWith('### ')) currentTextType.textContent = textType.h3
            else if(lineText.startsWith('## ')) currentTextType.textContent = textType.h2
            else if(lineText.startsWith('# ')) currentTextType.textContent = textType.h1
            else currentTextType.textContent = textType.p
          }
        })
      }
    } else {
      element.addEventListener('focus', () => {
        element.classList.add('is-focused')
      })

      element.addEventListener('blur', () => {
        element.classList.remove('is-focused')
      })
    }
  }

  checkForFocus(component, markdown) {
    if (component.element.classList.contains('is-focused')) {
      if(markdown) {
        component.wysiwyg.codemirror.focus()
      } else {
        component.element.focus()
      }
    }
  }


  _replaceSelection(cm, active, startEnd, url) {
    if(/editor-preview-active/.test(cm.getWrapperElement().lastChild.className))
      return;

    var text;
    var start = startEnd[0];
    var end = startEnd[1];
    var startPoint = cm.getCursor("start");
    var endPoint = cm.getCursor("end");
    if(url) {
      end = end.replace("#url#", url);
    }
    if(active) {
      text = cm.getLine(startPoint.line);
      start = text.slice(0, startPoint.ch);
      end = text.slice(startPoint.ch);
      cm.replaceRange(start + end, {
        line: startPoint.line,
        ch: 0
      });
    } else {
      text = cm.getSelection();
      cm.replaceSelection(start + text + end);

      startPoint.ch += start.length;
      if(startPoint !== endPoint) {
        endPoint.ch += start.length;
      }
    }
    cm.setSelection(startPoint, endPoint);
    cm.focus();
  }
}

export default InputTextArea
