import BaseComponent from './BaseComponent'

class ModalTrigger extends BaseComponent {
  constructor(componentName){
    super(componentName)

    this.actives = []
  }


  init(component) {
    this.addCustomEvents(component)
  }


  addCustomEvents(component) {
    const element   = component.element
    const modalName = element.dataset.modalTarget
    const modals    = document.getElementsByClassName('modal')
    let modalTarget

    for (let modal of modals) {
      if (modalName === modal.dataset.modal) {
        modalTarget = modal
        break
      }
    }

    // Exception for the content--spacer,
    // now it triggers the modal form without open it
    if(modalTarget) {
      if(modalName == 'modal--content--one_column--spacer') {
        element.addEventListener('click', () => {
          const form = modalTarget.getElementsByClassName('form')[0]

          if(form) {
            const inputPosition = form.querySelector('input[name="content[position]"]')
            if(inputPosition) inputPosition.value = null
            app.components.form.submitDataOfForm(form)
          }
        })
      } else {
        // Open Modal
        element.addEventListener('click', () => {
          for (let modal of this.actives) {
            modal.classList.add('is-unfocused')
          }
          modalTarget.classList.add('is-active')
          this.actives.push(modalTarget)
          document.body.classList.add('scroll-locked')

          // Detect preview images or files
          const form = modalTarget.getElementsByClassName('form')[0]
          if(form) {
            const previews = form.getElementsByClassName('file-preview-wrapper')
            for(let preview of previews) {
              const img = preview.getElementsByTagName('img')[0]
              const ext = preview.dataset.ext

              if(img) {
                if(img.src) preview.classList.add('has-content')
              } else if(ext === 'pdf' || ext === 'zip') {
                const iconString = `
                  <i class='preview-type'>
                    <img src='/assets/cms/i--${ext}.svg' alt='select option thumbnail'  alt='Drop file here'/>
                  </i>`
                const icon = app.parser.parseToHTML(iconString)
                preview.appendChild(icon)
                preview.classList.add('has-content')
              }
            }
          }
        })
      }
    }
  }


  openModal(modalTarget, autoclose = false) {
    if (modalTarget.classList.contains('is-active')) return
    const modals = document.getElementsByClassName('modal-wrapper')

    for (let modal of this.actives) {
      modal.classList.add('is-unfocused')
    }

    modalTarget.classList.add('is-active')
    this.actives.push(modalTarget)
    //lock
    document.body.classList.add('scroll-locked')


    if (autoclose) {
      window.setTimeout(() => {
        app.components.modal.closeModal(modalTarget, false)
      }, 3000)
    }
  }
}


export default ModalTrigger
