import BaseComponent from './BaseComponent';
import flatpickr     from 'flatpickr';


class DatePicker extends BaseComponent {
  constructor(componentName){
    super(componentName)

    this.options = {
      dateFormat: 'Y-m-d',
      minDate: 'today',
      'locale': {
        'firstDayOfWeek': 1  // start week on Monday
      },
      enableTime: false,
      time_24hr: true,
      noCalendar: false,
      hourIncrement: 1,
      minuteIncrement: 5,
      enableSeconds: false,
      onValueUpdate: (arrOfDateObjects, dateString, instance) => {
        if(dateString.length > 0) {
          instance.element.classList.add('has-content')
        } else {
          instance.element.classList.remove('has-content')
        }
      }
    }
  }


  init(component) {
    const element     = component.element
    const mode        = element.dataset.mode || 'single'  // single, range, multiple
    const enableTime  = element.dataset.datetime == 'true'
		const noCalendar  = element.dataset.justTime == 'true'
    const dateFormat  = enableTime ? 'Y-m-d H:i' : this.options.dateFormat
		const defaultDate = element.defaultValue
    const customOptions = {
			dateFormat,
      enableTime,
      mode,
      noCalendar,
		}


		if(defaultDate) customOptions.defaultDate = defaultDate

		component.datePicker = flatpickr(element, { ...this.options, ...customOptions })

		const clearButton = element.parentElement.getElementsByClassName('clear-input') [0]
		if(clearButton) {
			clearButton.addEventListener('click', () => {
				component.datePicker.clear()
				component.datePicker.jumpToDate('today')
				element.classList.remove('has-content')
			})
		}

  }
}

export default DatePicker;
